<template>
  <div class="body">
    <iframe id="pdfDom" :src="'https://www.apesk.com/lee/new/index_2_utf.asp?id=' + id" frameborder="0" />
    <div class=" print" @click="doPrint()">
      <el-button type="primary" icon="el-icon-download" circle></el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      id: "441181",
    };
  },
  methods: {
    doPrint() {

      let newStr = document.getElementById("pdfDom").contentWindow
      console.log(newStr)

      // let oldStr = window.document.body.innerHTML;
      // window.document.body.innerHTML = newStr;

      // window.document.body.innerHTML = oldStr;


      // window.print();
      // window.pageYoffset = 0;
      // document.documentElement.scrollTop = 0;
      // document.body.scrollTop = 0;
      // this.getPdf("#pdfDom");
    },
  },



  mounted() {
    const head = document.getElementById("LuHead");
    head.style.display = "none";
    const foot = document.getElementById("footer");
    foot.style.display = "none";
  },
  beforeDestroy() {
    const foot = document.getElementById("footer");
    foot.style.display = "block";
    const head = document.getElementById("LuHead");
    head.style.display = "block";
  },
  created() {
    this.id = this.$route.query.id;
  },

};
</script>

<style scoped>
.body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

iframe {
  margin: 0;
  border-style: none;
  width: 100%;
  height: 100%;
}

.print {
  position: fixed;
  bottom: 100px;
  right: 200px;
}
</style >
